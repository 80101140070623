@import url("https://use.typekit.net/gyk8zus.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@font-face{
  font-family: "iAWriterMono";
  src: url("./fonts/iAWriterMonoS-Regular.woff2") format('woff2');
  font-weight: normal;
}
@font-face{
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackRegular.ttf");
  font-weight: normal;
}
.content{
  height: auto;
}
.no-scroll {
  height: 100%;
  overflow: hidden;
}
.title{
  font-family: "bookmania", sans-serif;
  font-weight: 900;
  font-size: calc(24px + 32 * (100vw - 320px) / 1120);
  line-height: calc(32px + 32 * (100vw - 320px) / 1120);
  white-space: nowrap;
  letter-spacing: .035em;
}
.subtitle{
  display:inline-block;
  justify-content: center; 
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: calc(16px + 16 * (100vw - 320px) / 1120);
  color: black;
  opacity: .95;
}
a{
  color: #1BB994;
  text-decoration: none;
  transition: color 150ms ease;
}

a:hover{
  color: black;
  text-decoration: none;
  opacity: .95;
  transition: color 150ms ease;
}

a:active, a:hover{
  outline: 0;
}

.content {
  z-index: -1;
}

.content-body-text{
  top: 90px;
  right: 90px;
  bottom: 90px;
  left: 90px;
  width: auto;
  height: auto;
  display:block;
  position: absolute;
  text-align:center;
}

/* @media only screen and (max-width: 990px) {
  .content-body-text{
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    width: auto;
    height: auto;
    display:block;
    position: absolute;
    text-align:center;
  }
} */
.center-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hamburger-icon:hover {
  cursor: pointer;
}

/* .menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #FED269;
  overflow: hidden;
  z-index: 2;
} */

/* @keyframes drop {
  0% {
    margin-bottom: 100%;
    height: 0%; 
  }

  100% {
    margin-bottom: 0%;
    height: 100%;
    overflow: hidden;
  }
} */

/* .rise {
  animation: rise 0.4s;
  animation-timing-function: ease-out;
  margin-bottom: 100%;
}

@keyframes rise {
  0% {
    margin-bottom: 0%;
    height: 100%; 
  }

  100% {
    margin-bottom: 100%;
    height: 0%;
  }
} */

/* .hidden {
  height: 0px;
  margin-bottom: 100%; */
  /* display: none;
  opacity: 0;
  visibility: hidden;
} */


.menu{
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -100%, 0);
  width: 100%;
  height: 100%;
  background-color: #EFB764;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: opacity 700ms step-end, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94), visibility 700ms step-end, background-color 700ms cubic-bezier(0.8, 0, 0.55, 0.94);
}
.menu.drop {
  /* animation: drop 0.4s;
  animation-timing-function: ease-in;
  overflow: hidden; */
  opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: opacity 700ms step-start, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94), visibility 700ms step-start, background-color 700ms cubic-bezier(0.8, 0, 0.55, 0.94);
}
.menu.drop .menu-link {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.8, 0, 0.55, 0.94) 500ms;
}
.bars{
  /* width: 100%;
  display: flex; */
  position: absolute;
  z-index: -1;
  display: flex;
  width: inherit;
  height: 100%;
}
.column{
  border-left: 1px solid rgb(255, 255, 255);
  height: 100%;
  flex: 1;
  opacity: .3;
}
.column:last-child{
  border-right: 1px solid rgb(255, 255, 255);
}

.menu-container {
  width: 90%;
  margin-left:auto;
  margin-right: auto;
  height: 100%;
}

.menu-text{
  padding-top: 24vh;
}

.menu-link{
  opacity: 0;
  font-family: "bookmania", sans-serif;
  font-weight: 900;
  font-size: calc(24px + 40 * (100vw - 320px) / 1120);
  line-height: calc(45px + 33 * (100vw - 320px) / 1120);
  letter-spacing: .05em;
  color: white;
  cursor: pointer;
  transition: opacity 400ms cubic-bezier(0.8, 0, 0.55, 0.94);
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.menu-link::before{
  position: absolute;
  content: attr(data-content); 
  background-image: linear-gradient(to bottom, 
        #FFFFFF 0%,
        #FFFFFF 50%,
        #364262 50%,
        #364262 100%
    );
  background-size: 100% 200%;
  width: 100%;
  height: 100%;
  text-decoration: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-position: 0% 200%;
  animation-name: exitText;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(.5,0,.5,1);
}
@keyframes hoverText{
  from{
    background-position: 0% 200%;
  }
  to{
    background-position: 0% 100%;
  }
}
@keyframes exitText{
  from{
    background-position: 0% 100%;
  }
  to{
    background-position: 0% 0%;
  }
}

.menu-link:hover::before {
  animation-name: hoverText;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.5,0,.5,1);
}

.fa {
  font-size: 32px;
  text-decoration: none;
}
.social-container{
  display: inline-flex;
  position: relative;
  width: 48px;
  height: 48px;
  border: 2px solid white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  justify-content: center;
  align-items: center;
  opacity: .9;
  margin-top: calc((45px + 33 * (100vw - 320px) / 1120) * 2/3);
  margin-right: 1vw;
}

/* a:hover .social-container{
  border: 1px solid black;
  transition: border 200ms ease;
} */
a:hover i{
  color: #EFB764;
  transition: color 100ms ease 100ms;
}
a:hover i.fa-palette{
  color: black;
  transition: all .3s ease;
}
i.fa{
  color: white;
  opacity: .9;
  z-index: 2;
  transition: color 100ms ease 100ms;
}

i.fab{
    color: white;
    opacity: .9;
    z-index: 2;
    transition: color 100ms ease 100ms;
}

.social-background{
  display: block;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 1;
  box-sizing: border-box;
  outline: none;
}

.social-background-inner{
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  transition: -webkit-transform 300ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  transition: transform 300ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  transition: transform 300ms cubic-bezier(0.77, 0, 0.175, 1) 0ms, -webkit-transform 300ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  outline: none;
  box-sizing: border-box;
}

a:hover .social-background-inner{
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

.header{
  position: fixed;
  width:90%;
  margin-left:5%;
  margin-right:5%;
  padding-top: 36px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}

.header-left{
  pointer-events: auto;
}

.header-right{
  display: inline-block;
  pointer-events: auto;
}

.logo-wrapper{
  width: 64px;
  height: 64px;
  margin-top: 10px;
}
span.scroll-cue {
  padding-top: 60px;
}
span.scroll-cue span:after {
  content: "";
  position: fixed;
  top: 0%;
  left: 100%;
  width: 5px;
  height: 5px;
  /* background-color: #C9EDBD; */
  background-color: #1BB994;
  -webkit-transform: translate(-100%,0) rotate(-90deg);
  transform: translate(-100%,0) rotate(-90deg);
  box-sizing: border-box;
  z-index: 2;
}
span.scroll-cue span {
  position: fixed;
  top: 95%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-left: 2px solid #1BB994;
  border-bottom: 2px solid #1BB994;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  -webkit-animation: fadeIn 1s, pulse 1.8s ease .5s 3;
  animation: fadeIn 1s, pulse 1.8s ease .5s 3;
  box-sizing: border-box;
  z-index: 2;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  50% {
    -webkit-transform: rotate(-45deg) translate(-5px, 5px);
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes pulse {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  50% {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  100% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  30% {opacity: 0;}
  100% { opacity: 1; }
}
.hamburger-circle{
  width: 46px;
  height:46px;
  margin-right: 4px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.185);
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20); */
  position: relative;
  transition: transform .2s ease,box-shadow .2s ease
}
.hamburger-circle:hover{
  transform: perspective(5em) translate3d(0,0,10px);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  transform: scale(1.12,1.12)
}

.logo path, .logo rect, .logo polygon, .logo g{
  transition: all .2s cubic-bezier(0.63, 0.34, 0.1, 0.98) .5s;
}

.from-bottom path, .from-bottom rect, .from-bottom polygon, .from-bottom g{
  transition: all .2s cubic-bezier(0.63, 0.34, 0.1, 0.98) .05s !important;
}

.from-menu path, .from-menu rect, .from-menu polygon, .from-menu g{
  transition: all .2s cubic-bezier(0.63, 0.34, 0.1, 0.98) .17s !important;
}
.menuSVG path {
  fill: none;
  stroke: var(--color);
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  /* transition: all .8s cubic-bezier(.645, .045, .355, 1); */
  transition: all .8s cubic-bezier(0.63, 0.34, 0.1, 0.98)
}

circle {
  opacity: 0;
}
input[type="checkbox" i]{
  display:none;
}

label {
  cursor: pointer;
  position: absolute;
  width: 46px;
  height: 46px;
}

.cross input:checked+svg .line--1,
  .cross input:checked+svg .line--3 {
    --length: 22.627416998;
    --color: #EFB764;
  }

.cross input:checked+svg .line--2 {
    --length: 0;
    --color: #EFB764;
  }

.back input:checked+svg .line--1,
  .back input:checked+svg .line--3 {
    --length: 8.602325267;
    --color: #EFB764;
  }

.menu--1 .line--1,
  .menu--1 .line--3 {
    --total-length: 126.64183044433594;
    --color:  #1BB994;
  }

.menu--1 .line--2 {
    --total-length: 70;
    --color:  #1BB994;
  }

.menu--1 input:checked+svg .line--1,
    .menu--1 input:checked+svg .line--3 {
      --offset: -94.1149185097;
      --color: #EFB764;
    }

.menu--1 input:checked+svg .line--2 {
      --offset: -50;
      --color: #EFB764;
    }

.wave{
  position: absolute;
  top: 100%;
  transform: translate(0,-65%);
  width: 100%;
  /* animation-name: wave;
  animation-duration: 1500ms;
  animation-timing-function: cubic-bezier(0.34, 1.38, 0.64, 1); */
}

/* @keyframes wave{
  from{
    transform: translate(0,0%);
  }
  to{
    transform: translate(0,-50%)
  }
} */

.waveContainer{
  position: relative;
  width: 131.125vw;
  left: -15.625vw;
  height: 100%;
  z-index: 4;
}
.wave-move-up{
  transform: translate(0,-99.9%);
  transition: transform 1.1s cubic-bezier(0.14, 0, 0.24, 0.99);
}
.wave-move-down{
  transform: translate(0, -65%);
  transition: transform 1s;
}
/* .ease{
  transition-delay: .4s !important;
} */

.textContainer{
  position: absolute;
  max-width: 30%;
  left: 49%;
  top:52%;
  z-index: 5;
}

.leftTextContainer{
    position: absolute;
    max-width: 25%;
    left: 15%;
    top:50%;
    z-index: 5;
}

.rightTextContainer{
    position: absolute;
    max-width: 25%;
    left: 60%;
    top:50%;
    z-index: 5;
}

.graphTextContainer{
    position: absolute;
    max-width: 25%;
    left: 15%;
    top:40%;
    z-index: 5;
}

.shortCaption{
    font-family: "Poppins", sans-serif;
    font-size: calc(10px + 9 * (100vw - 320px) / 600);
    letter-spacing: .035em;
}

.longCaption{
  font-family: "Poppins", sans-serif;
  font-size: calc(10px + 9 * (100vw - 320px) / 1120);
  letter-spacing: .035em;
}
.imageContainer{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top:0;
  left:0;
  z-index: 3;
}
#leftCloud{
  position: absolute;
  width: 50%;
  top: 100%;
  transform: translate(0,-100%);
  z-index: 3;
}
#rightCloud{
  position: absolute;
  top: 100%;
  left: 100%;
  width: 25%;
  transform: translate(-100%,-100%);
  z-index: 3;
}
#mainAirplane{
  position: absolute;
  width: 40%;
  top: 125%;
  left: -2%;
  transform: translate(2%,-125%);
  z-index: 3;
}
#topAirplane{
  position: absolute;
  width: 13%;
  top: 5%;
  left: 30%;
  transform: translate(-30%,-5%);
  z-index: 3;
}

#rightAirplane{
  position: absolute;
  width: 25%;
  top: 30%;
  left: 80%;
  transform: translate(-80%,-30%);
  z-index: 3;
}

#leftAirplane{
  position: absolute;
  width: 13%;
  top: 23%;
  left: 1%;
  transform: translate(-1%,-23%);
  z-index: 3;
}

#book{
    position: absolute;
    width: 65%;
    top: 33%;
    left: 110%;
    transform: translate(-110%,-33%);
    z-index: 1;
}

#bookCloud{
    position: absolute;
    width: 20%;
    top: 35%;
    left: 93%;
    transform: translate(-93%,-35%);
    z-index: 2;
}

#bookPlanes{
    position: absolute;
    width: 20%;
    top: 33%;
    left: 65%;
    transform: translate(-65%,-33%);
    z-index: 3;
}

#graphPlane{
    position: absolute;
    width: 23%;
    top: 50%;
    left: 85%;
    transform: translate(-85%,-50%);
    z-index: -1;
}

#graphBotLeft{
    position: absolute;
    width: 50%;
    top: 100%;
    left: -20%;
    transform: translate(20%,-100%);
    z-index: 2;
}

#graphBotRight{
    position: absolute;
    width: 55%;
    top: 95%;
    left: 115%;
    transform: translate(-115%,-90%);
    z-index: 2;
}

#graphTopRight{
    position: absolute;
    height: 45%;
    top: 0%;
    left: 90%;
    transform: translate(-90%,-0%);
    z-index: 2;
}

#graphCircle{
  position: absolute;
  width: 5%;
  top: 25%;
  left: 62%;
  transform: translate(-62%,-25%);
  z-index: 2;
}

#graphBlue{
  position: absolute;
  width: 18%;
  top: 77%;
  left: 87%;
  transform: translate(-87%,-77%);
  z-index: 2;
}

#graphTopBlue{
    position: absolute;
    width: 18%;
    top: 0%;
    left: 90%;
    transform: translate(-90%,0%);
    z-index: 3;
}

#graphPink{
  position: absolute;
  width: 18%;
  top: 93%;
  left: 22%;
  transform: translate(-22%,-93%);
  z-index: 2;
}

#graphTopPink{
    position: absolute;
    width: 18%;
    top: 18%;
    left: 85%;
    transform: translate(-85%,-18%);
    z-index: 3;
}

#graphBotPink{
    position: absolute;
    width: 20%;
    top: 100%;
    left: 75%;
    transform: translate(-75%,-100%);
    z-index: 3;
}

#graphGreen{
  position: absolute;
  width: 13%;
  top: 90%;
  left: 60%;
  transform: translate(-60%,-90%);
  z-index: 2;
}

#graphTopGreen{
    position: absolute;
    width: 18%;
    top: 2%;
    left: 69%;
    transform: translate(-69%,-2%);
    z-index: 3;
}

#graphYellow{
  position: absolute;
  width: 11%;
  top: 37%;
  left: 97%;
  transform: translate(-97%,-37%);
  z-index: 2;
}

#graphOrange{
    position: absolute;
    width: 8%;
    top: 85%;
    left: 0%;
    transform: translate(-0%,-85%);
    z-index: 3;
}

#graphCorner{
  position: absolute;
  width: 11%;
  top: 100%;
  left: 100%;
  transform: translate(-100%,-100%);
  z-index: 2;
}

#graphShapes{
    position: absolute;
    width: 100%;
    top: 100%;
    left: 100%;
    transform: translate(-100%,-100%);
    z-index: 3;
}


#phone{
    position: absolute;
    width: 55%;
    top: 100%;
    left: 0%;
    transform: translate(0%,-100%);
    z-index: -1;
}
#phoneScene{
  z-index: -1;
}

/* #phonePopups{
    position: absolute;
    width: 25%;
    top: 50%;
    left: 35%;
    transform: translate(-35%,-50%);
    z-index: 2;
} */
#popupTop{
  position: absolute;
  width: 11%;
  top: 37%;
  left: 28%;
  transform: translate(-28%,-37%);
  z-index: 2;
}

#popupBottom{
  position: absolute;
  width: 15%;
  top: 67%;
  left: 45%;
  transform: translate(-45%,-67%);
  z-index: 2;
}

#phonePlane{
    position: absolute;
    width: 25%;
    top: 25%;
    left: 0%;
    transform: translate(-0%,-25%);
    z-index: 3;
}

.fp-tableCell{
  vertical-align: initial;
}

.subtitle-med{
  font-family: "Poppins";
  font-size: calc(10px + 10 * (100vw - 320px) / 1120);
}

.inline-block-center{
  display:inline-block;
  justify-content: center; 
  width: 100%;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .wave{
    width: 230%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) and (orientation: portrait) {
  .wave{
    /* right: 20%; */
    /* top: 100%;
    transform: translate(-20%, -65%); */
    width: 180%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .header{
    padding-top: 24px;
  }
  #topAirplane{
    display: none;
  }

  #leftAirplane{
    display: none;
  }

  #rightAirplane{
    display: none;
  }

  #mainAirplane{
    height: 38%;
    width: auto;
    top: 100%;
    transform: translate(0, -100%);
    /* z-index: -1; */
  }

  #leftCloud{
    height: 20%;
    width: auto;
  }

  #rightCloud{
    height: 30%;
    width: auto;
  }

#graphBotLeft{
  position: absolute;
  height: 40%;
  width: auto;
  top: 90%;
  left: 20%;
  transform: translate(-90%,-100%);
  z-index: 0;
}

#graphBotRight{
  display: none;
}

#graphTopRight{
    position: absolute;
    height: 40%;
    top: 0%;
    left: 90%;
    transform: translate(-90%,-0%);
    z-index: 2;
}

#graphPlane{
  height: 26%;
  top: 80%;
  width: auto;
}

#graphShapes{
  height: auto;
  width: 100%;
}


#book{
    position: absolute;
    width: unset;
    height: 55%;
    top: 100%;
    left: -20%;
    transform: translate(0%,-100%);
    z-index: -1;
}

#bookCloud{
    position: absolute;
    width: unset;
    height: 10%;
    top: 60%;
    left: 93%;
    transform: translate(-93%,-60%);
    z-index: 2;
}

#bookPlanes{
    position: absolute;
    width: unset;
    height: 15%;
    top: 63%;
    left: 33%;
    transform: translate(-33%,-63%);
    z-index: 3;
}

#phone{
    position: absolute;
    width: unset;
    height: 45%;
    top: 90%;
    left: 0%;
    transform: translate(0%,-100%);
    z-index: -1;
}

#phonePopups{
    display: none;
    position: absolute;
    width: unset;
    height: 20%;
    top: 75%;
    /* left: 70%; */
    transform: translate(-70%,-75%);
    z-index: 3;
}

#popupTop{
    position: absolute;
    height: 8%;
    width: auto;
    top: 50%;
    left: 54%;
    transform: translate(-54%,-50%);
    z-index: 2;
}


#popupBottom{
    position: absolute;
    height: 10%;
    width: auto;
    top: 77%;
    left: 77%;
    transform: translate(-77%,-77%);
    z-index: 2;
}

#phonePlane{
    position: absolute;
    width: unset;
    height: 26%;
    top: 44%;
    left: -11%;
    transform: translate(11%,-44%);
    z-index: 2;
}

  .textContainer{
    /* display: none; */
    /* top: 20%; */
    /* bottom: 30%; */
    transform: translate(0, -100%) !important;
    left: 12%;
    max-width: 80%;
  }

  .leftTextContainer{
    transform: translate(0, -150%) !important;
    left: 12%;
    max-width: 80%;
  }

  .rightTextContainer{
    transform: translate(0, -175%) !important;
    left: 12%;
    max-width: 90%;
  }

  .graphTextContainer{
    max-width: 70%;
  }

  .shortCaption{
    font-size: calc(10px + 9 * (100vh - 320px) / 300);
  }

  .longCaption{
    font-size: calc(10px + 9 * (100vh - 320px) / 600);
  }

  .subtitle2{
    width: 80%;
    /* transform: translate(-16%, 0); */
    font-size: calc(10px + 9 * (100vh - 320px) / 600);
  }
}

/* landscape phones */
@media only screen and (max-height: 500px) and (orientation: landscape) {
  .textContainer{
    top:42%;
    /* left: 130%; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1200px) and (min-height: 500px) and (orientation: landscape) {
  #mainAirplane{
    /* height: 55%;
    width: auto; */
    height: auto;
    width: 45%;
    top: 100%;
    transform: translate(0, -100%);
  }

  /* #leftCloud{
    width: 52%;
    height: auto;
    right: 50%;
    transform: translateX(-50%);
  } */

  /* #rightCloud{
    height: 30%;
    width: auto;
  } */

  .textContainer{
    /* display: none;
    top: 20%;
    bottom: 30%;
    transform: translate(0, -100%) !important;
    max-width: 80%; */
    left: 50%;
    top: 60%;
  }

  .longCaption{
    font-size: calc(10px + 9 * (100vh - 320px) / 600);
  }
  @media only screen and (min-width: 768px) and (max-width: 850px) and (min-height: 500px) and (orientation: landscape) {
    #mainAirplane{
      /* height: 55%;
      width: auto; */
      height: auto;
      width: 55%;
      top: 100%;
      transform: translate(0, -100%);
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

} 

.links{
  list-style: none;
  padding: 0;
  margin: 0;
}

.link{
  display: inline-block;
  vertical-align: middle;
  list-style-image: none;
  list-style-type: none;
}

.link a{
  color: black;
  opacity: .95;
  transition: color 200ms ease;
}

.link a:hover{
  color: #1BB994;
  opacity: .95;
}
.link:after{
  content: '\2022';
  margin-left: 6px;
  margin-right: 6px;
  color: #1BB994;
}

.link:last-child:after{
  content: '';
}

/* @supports (-webkit-touch-callout: none){
  @media only screen and (orientation: portrait){
    .menu-container {
      height: -webkit-fill-available;
    }
    .imageContainer{
      height: -webkit-fill-available;
    }
    .waveContainer{
      height: -webkit-fill-available;
    }
  }
} */
#absolute{
  width: 100%;
  height: 100%;
}
.hover-reveal {
  position: absolute;
  z-index: -2;
  width: 228px;
  height: 150px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
}

.hover-reveal__inner {
  overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
}

.hover-reveal__img {
  background-size: cover;
  background-position: 50% 50%;
}

@supports (-webkit-touch-callout: none) {
  .hover-reveal {
    display: none;
  }
}

.about-section-1{
  width: 100%;
  display:block;
  padding-top: 180px;
}

.about-title{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.about-subtitle {
  font: normal 20px 'Poppins';
}

.about-subtitle-1 {
  width: 49%;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .035em;
  text-align: center;
}

#skytram{
  width: 100%;
  position: relative;
  top: -125px;
  left: 0;
  pointer-events: none;
}

.color-black{
  color: black;
  opacity: .95;
}

@media only screen and (max-width: 1024px) {
  .about-subtitle {
    font: 14px/1.6 'Poppins';
  }

  .about-subtitle-1 {
    width: 80%;
  }
}

.about-section-2{
  width: 100%;
  display: block;
}

.about-title-2{
  font-family: 'Bookmania';
  margin-left: 10%;
  margin-bottom: 5%;
}

.icon-container{
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: flex-start;
}
.icon{
  max-width: 320px;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-small{
  font-family: "bookmania", sans-serif;
  font-weight: 900;
  font-size: calc(16px + 16 * (100vw - 320px) / 1120);
  letter-spacing: .035em;
}

.icon-text{
  text-align: center;
}

a .icon-title{
  color: black;
  opacity: .95;
  transition: color 150ms ease;
}

a .icon-title:hover{
  color: #1BB994;
  opacity: .95;
}


#resources{
  width: 100%;
}

#workshopBook{
  width: 100%;
}

#community{
  width: 100%;
}

.about-section-3{
  padding-top: 7%;
}

.about-title-3{
  font-family: 'Bookmania';
  margin-left: 10%;
  margin-bottom: 5%;
}

.team-info-container{
  column-gap: 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  row-gap: 4rem;
  width: 80%;
}

.team-info-shape{
  border: solid black;
  border-radius: 20px;
  text-align: center;
  padding: 2rem 1rem;
}

.team-join{
  margin: auto;
  width: 80%;
}

@media only screen and (max-width: 1024px) {
  .team-info-container{
    display: block;
    margin: auto;
    width: 80%;
  }

  .team-info-shape{
    margin: 1rem auto;
  }
}

.about-section-4{
  padding-top: 14%;
}

.about-title-4{
  font-family: 'Bookmania';
  margin: 0 10% 5%;
  white-space: normal;
}

.decoration-sparkle {
  position: absolute;
  z-index: 0;
}

.gallery-container{
  column-gap: 2vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, auto));
  justify-items: center;
  margin: auto;
  max-width: 80%;
  position: relative;
  row-gap: 2vw;
  z-index: 1;
}

#box{
  max-width:100%;
  object-fit: contain;
  height:333px;
  border-radius: 4px;  
}

.team-member-container{
  position: relative;
  transform-style: preserve-3d;
  transform: perspective(300px);
}

.team-member-image{
  width: 100%;
}

.team-member-name{
  font: 28px 'Poppins';
}

.group-member-image{
  max-width: 50em;
  width: calc(17.5em + 32 * (100vw - 320px) / 100);
  height: auto;
}

@media only screen and (max-width: 1024px) {
  .decoration-sparkle {
    display: none;
  }

  .team-member-name{
    font: 24px 'Poppins';
  }
}

.opacity-transition{
  transition: opacity .5s ease;
}
.opacity-transition-delay{
  transition: opacity .5s ease .5s;
}

.team-member-hover{
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0;
  transition: all .5s ease;
}
.team-member-container:hover .team-member-hover{
  opacity: 1;
  transition: all .5s ease;
}
/* circles */
.lg-orange-circle{
  background-color: #FED06F;
  width: 81%;
  height: 81%;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sm-orange-circle{
  background-color: #FED06F;
  z-index: 2;
  width: 15%;
  height: 15%;
  margin: 0 auto;
  position: absolute;
  border-radius: 50%;
  transform: scale(0) translateZ(0px) translateX(0px);
  opacity: 0;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.27);
}
.md-orange-circle{
  background-color: #fca71f;
  width: 47.1%;
  height: 47.1%;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  z-index: 1;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.lg-green-circle{
  width: 81%;
  height: 81%;
  background-color: #5bae9b;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.md-green-circle{
  width: 54%;
  height: 54%;
  background-color: #366b6a;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(1px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.lg-red-circle{
  width: 81%;
  height: 81%;
  background-color: #f26f6f;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.md-red-circle{
  width: 54%;
  height: 54%;
  background-color: #E89FA6;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(1px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.blue-circle{
  width: 81%;
  height: 81%;
  background-color: #BDE7EEff;
  position: absolute;
  left: 16%;
  top: -6%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dark-blue-circle{
  width: 54%;
  height: 54%;
  background-color: #5F9DC2;
  position: absolute;
  left: 5%;
  top: 30%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(1px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.golgi{
  z-index: 2;
  position: absolute;
  width: 50%;
  top: 32%;
  left: -8%;
  opacity: 0;
  transform-origin: bottom right;
  transform: scale(0) skew(30deg) rotate(30deg) translateZ(3px);
  transition: all 1s ease;
  filter: url(#filter);
}
.sm-golgi{
  z-index: 2;
  position: absolute;
  width: 32%;
  top: 30%;
  left: 45%;
  opacity: 0;
  transform-origin: bottom right;
  transform: scale(0) skew(30deg) rotate(30deg) translateZ(10px);
  transition: all 1s ease;
}
.donut{
  position: absolute;
  width: 90%;
  top: -5%;
  left: -2%;
  transition: all 1s ease;
  opacity: 0;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
}
.sm-donut{
  position: absolute;
  width: 20%;
  left: 78%;
  top: 55%;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
  transition: all 1s ease;
  filter: url(#filter);
  opacity: 0;
}
.cool-shape{
  z-index: 2;
  position: absolute;
  width: 80%;
  top: -10%;
  left: 30%;
  opacity: 1;
  transform-origin: bottom right;
  transform: scale(0) skew(30deg) rotate(40deg) translateZ(24px);
  transition: all 1.5s ease;
  filter: url(#filter);
}
.cool-shape-2{
  position: relative;
  z-index: 999;
  /* width: 100%; */
  width: 113%;
  /* top: 30%;
  left: -110%; */
  top: 16%;
  left: -125%;
  opacity: 0;
  transform: scaleX(-1) scale(0) rotate(-25deg) translateZ(15px);
  transform-origin: bottom right;
  /* transition: opacity 0s; */
  transition: all 1.5s ease;
}
.cover .cool-shape-2{
  position: relative;
  z-index: 999;
  width: 100%;
  top: 30%;
  left: -110%;
  opacity: 0;
  transform: scaleX(-1) scale(0) rotate(-25deg) translateZ(25px);
  transform-origin: bottom right;
  transition: all 1.5s ease;
}
.rose{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: -6%;
  left: -12%;
  opacity: 1;
  /* transform-origin: bottom right; */
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#filter);
}

.rose2{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 30%;
  left: 20%;
  opacity: 1;
  /* transform-origin: bottom right; */
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#filter);
}
.wings{
  z-index: 2;
  position: absolute;
  transform: translateX(-25%) translateY(50%) scale(0.5);
  bottom: -10%;
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  width: 210%;
  filter: url(#filter);
}
.color-smoke{
  z-index: 2;
  position: absolute;
  width: 70%;
  top: -6%;
  left: -9%;
  opacity: 1;
  transform-origin: bottom right;
  transform: scale(0) skew(30deg) rotate(30deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#turbulence);
}

.color-smoke-2{
  z-index: 2;
  position: absolute;
  width: 75%;
  top: 3%;
  left: 34%;
  opacity: 1;
  transform-origin: bottom left;
  transform: scale(0) skew(-30deg) rotate(-30deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#turbulence);
}
/* alex */
.orange-v1 .lg-orange-circle{
  top: -6%;
  left: -3%;
}
.orange-v1 .sm-orange-circle{
  top: 65%;
  left: 80%;
}
.orange-v1 .md-orange-circle{
  top: 30%;
  left: 50%;
}
/* ashley */
.blue-v1 .md-green-circle{
  left: 5%;
  top: 30%;
}
.blue-v1 .lg-green-circle{
  left: 16%;
  top: -6%;
}
.blue-v1 .dark-blue-circle{
  left: -2%;
  top: 35%;
}
/* connie */
.green-v1 .rose{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 15%;
  left: -20%;
  opacity: 1;
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1s ease;
  filter: url(#filter);
}

.green-v1 .rose2{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 30%;
  left: 20%;
  opacity: 1;
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1s ease;
  filter: url(#filter);
}
.green-v1 .green-circle{
  width: 81%;
  height: 81%;
  background-color: #BEE9CC;
  position: absolute;
  left: 16%;
  top: -6%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.green-v1 .dark-green-circle{
  width: 54%;
  height: 54%;
  background-color: #73af8e;
  position: absolute;
  left: 5%;
  top: 30%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(1px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* celine */
.red-v1 .lg-red-circle{
  left: 9%;
  top: -3%;
}
.red-v1 .md-red-circle{
  left: -2%;
  top: -2%;
}
/* esther */
.orange-v2 .sm-orange-circle{
  height: 30px;
  width: 30px;
  left: -5%;
  top: 48%;
  background-color: #fca71f;
}
.orange-v2 .md-orange-circle{
  top: -4%;
  left: -3%;
}
.orange-v2 .lg-orange-circle{
  top: -2%;
  left: -1%;
}
.orange-v2 .golgi{
  z-index: 2;
  position: absolute;
  width: 65%;
  top: 36%;
  left: -15%;
  opacity: 0;
  transform-origin: bottom right;
  transform: scaleX(-1) scale(0) skew(30deg) rotate(60deg) translateZ(3px);
  transition: all 1s ease;
  filter: url(#filter);
}
.orange-v2 .sm-golgi{
  z-index: 2;
  position: absolute;
  width: 25%;
  top: 70%;
  left: 22%;
  opacity: 0;
  transform-origin: bottom left;
  transform: scale(0) skew(30deg) rotate(0deg) translateZ(3px);
  transition: all 1s ease;
  filter: url(#filter);
}
.orange-v2 .team-member-container:hover .golgi{
  transition: all 1s ease;
  opacity: 1;
  transform: scaleX(-1) scale(1) skew(0deg) rotate(0deg) translateZ(3px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}
.orange-v2 .team-member-container:hover .sm-golgi{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(-70deg) translateZ(6px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}

.blue-v2 .donut{
  position: absolute;
  top: 40%;
  width: 50%;
  left: initial;
  transition: all 1s ease;
  opacity: 0;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
}
.blue-v2 .sm-donut{
  position: absolute;
  width: 25%;
  top: 23%;
  left: initial;
  right: 8%;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
  transition: all 1s ease;
  filter: url(#filter);
  opacity: 0;
}
.blue-v2 .dark-blue-circle{
  right: -2%;
  left: initial;
  top: 35%;
}
.blue-v2 .team-member-container:hover .dark-blue-circle{
  opacity: 1;
  transform: scale(1) translateZ(5px);
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.blue-v2 .blue-circle{
  left: 4%;
  top: -6%;
}

.blue-v3 .donut{
  position: absolute;
  top: 50%;
  width: 40%;
  left: -8%;
  transition: all 1s ease;
  opacity: 0;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
}
.blue-v3 .sm-donut{
  position: absolute;
  width: 30%;
  top: 25%;
  left: initial;
  right: 2%;
  transform: scale(0) skew(0deg) rotate(0deg) translateY(10px) translateZ(23px);
  transition: all 1s ease;
  filter: url(#filter);
  opacity: 0;
}
.blue-v3 .dark-blue-circle{
  top: 3%;
  left: 6%;
  width: 47.1%;
  height: 47.1%;
}
.blue-v3 .team-member-container:hover .dark-blue-circle{
  opacity: 1;
  transform: scale(1) translateZ(5px);
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) .5s;
}
.blue-v3 .blue-circle{
  top: 15%;
  left: 15%;
}
/* joyce */
.red-v2 .lg-red-circle{
  left: 9%;
  top: -5%;
}
/* spencer */
.green-v2 .rose{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 0%;
  left: -20%;
  opacity: 1;
  /* transform-origin: bottom right; */
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#filter);
}

.green-v2 .rose2{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 30%;
  left: 20%;
  opacity: 1;
  /* transform-origin: bottom right; */
  transform: scale(0) skew(0deg) rotate(0deg) translateZ(3px);
  transition: all 1.5s ease;
  filter: url(#filter);
}
.green-v2 .green-circle{
  width: 81%;
  height: 81%;
  background-color: #BEE9CC;
  position: absolute;
  left: 16%;
  top: -6%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(0px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.green-v2 .dark-green-circle{
  width: 54%;
  height: 54%;
  background-color: #73af8e;
  position: absolute;
  left: 5%;
  top: 30%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0) translateZ(1px);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
/* fox */
.fox .md-orange-circle{
  top: 3%;
  left: 6%;
}
.fox .lg-orange-circle{
  top: 15%;
  left: 15%;
}
/* team hovers */
.team-member-container:hover .color-smoke-2{
  transition: all 1.5s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(0deg) translateZ(3px);
}
.team-member-container:hover .color-smoke{
  transition: all 1.5s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(0deg) translateZ(3px);
}
.team-member-container:hover .circle-scale{
  opacity: 1;
  transform: scale(1) translateZ(0px);
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.team-member-container:hover .circle-scale-front{
  opacity: 1;
  transform: scale(1) translateZ(5px);
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.team-member-container:hover .circle-scale-quick{
  transform: scale(1) translateZ(3px) translateX(10px);
  opacity: 1;
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1);
}

.team-member-container:hover .circle-scale-delay{
  opacity: 1;
  transform: scale(1) translateZ(1px);
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) .5s;
}
.team-member-container:hover .rose{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(0deg) translateZ(3px);
}
.team-member-container:hover .rose2{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(0deg) translateZ(3px);
}
.team-member-container:hover .cool-shape-2{
  /* transition: opacity 0s linear 1.15s; */
  transition: all 1.5s ease;
  opacity: 1;
  /* transform: scaleX(-1) scale(1) skew(0deg) rotate(-25deg) translateZ(25px); */
  transform: scaleX(-1) scale(1) skew(0deg) rotate(-25deg) translateZ(18px);
  filter: url(#filter);
}
.team-member-container:hover .cover .cool-shape-2{
  transition: all 1.5s ease;
  opacity: 1;
  transform: scaleX(-1) scale(1) skew(0deg) rotate(-25deg) translateZ(25px);
}
.team-member-container:hover .cool-shape{
  transition: all 1.5s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(10deg) translateZ(3px);
}

.team-member-container:hover .golgi{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(-30deg) translateZ(3px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}

.team-member-container:hover .sm-golgi{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(70deg) translateZ(13px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}

.team-member-container:hover .donut{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(-60deg) translateY(-20px) translateZ(16px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}

.team-member-container:hover .sm-donut{
  transition: all 1s ease;
  opacity: 1;
  transform: scale(1) skew(0deg) rotate(40deg) translateY(-20px) translateZ(16px);
  filter: url(#filter);
  -webkit-filter: url(#filter);
}

.team-member-container:hover .wings{
  transform: translateX(-25%) translateY(17%) scale(1);
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@media only screen and (min-width: 600px) and (max-width: 768px) and (orientation: portrait) {
  .icon{
    max-width: 200px;
  }
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .gallery-container{
    display: grid;
    max-width: 80%;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    column-gap: 2vw;
    row-gap: 2vh;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-items: center;
  }
  
  #box{
    max-width:100%;
    object-fit: contain;
    height:200px;
    border-radius: 4px;  
  }
  .icon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon{
    max-width: 200px;
  }
  .icon-text {
    width: 200px;
  }
  #skytram{
    position: initial;
    display: block;
  }
  .about-section-2 {
      width: 100%;
      display: block;
      padding-top: 15%;
  }
}
.events{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 140px;
}

.resources, .merch {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 140px;
}

.resources-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    font-family: "Poppins";
}

.merch-section {
  width: 100%;
  margin-right: auto;
  max-width: 1100px;
  font-family: "Poppins";
}

.merch-button {
  background-color: invisible;
  font-family: "Poppins";
  border: 3px solid #356A69;
  color: #356A69;
  font-size: 24px;
  font-weight: bolder;
  padding: 8px 24px 8px;
  border-radius: 13px;
  transition: all .3s ease;
}

.merch-button:hover {
  background-color: #356A69;
  color: white;
  padding: 8px 24px 8px;
}

/* .merch-button {
  background-color: black;
  font-family: "Poppins";
  color: white;
  padding: 20px;
  font-size: 14px;
  line-height: 1;
  padding: 16px 48px 18px;
  transition-property: background-color, border-color, color, filter, transform;
  transition-duration: 0.3s;
  border-width: 0;
  letter-spacing: 2px;
  width: 392px;
  display: block;
  border: none;
  border-radius: 45px;
  text-transform: uppercase;
  white-space: normal;
  cursor: pointer;
}

.merch-button:hover {
  background-color: #fff;
  color: #000;
  transform: translateY(-3px);
} */

.masonry-grid{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.masonry-grid2{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.grid-item-img{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.grid-item{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 33.33333%;
  max-width: 33.33333%;
  flex: 0 0 auto;
  flex-basis: 33.33333%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}
.grid-item-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid-caption{
  padding-top: 10px;
  text-align: center;
}
.grid-caption-inline{
  display: flex;
}
a.grid-caption{
  color: black;
  opacity: .95;
  transition: color 150ms ease;
}
a.grid-caption:hover{
  color: #1BB994;
  opacity: .95;
}

.event-card{
  margin-left: 10%;
  max-width: 45%;
}
.event-card-right{
  max-width: 45%;
  margin-right: 10%;
  float: right;
}
.event-card-img{
  width: 100%;
}
.event-text{
  margin-top: 10px;
}
.event-title-container {
  display: flex;
}
.event-date{
  justify-content: flex-end;
  align-content: center;
  margin-left: auto;
}
.event-title {
  position: relative;
  justify-content: flex-start;
}
.event-title:after {
  position: absolute;
  content: '';
  height: 2px;
  bottom: -4px; 
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.95);
}
.event-subtitle-container {
  margin-top: 1rem;
}
a.event-title:hover{
  color: #1BB994;
  opacity: .95;
}
a.event-title{
  color: black;
  opacity: .95;
  transition: color 150ms ease;
}
a.event-subtitle:hover{
  color: #1BB994;
  opacity: .95;
}
a.event-subtitle{
  color: black;
  opacity: .95;
  transition: color 150ms ease;
}
.event-card-img{
  transition: all .3s ease;
}
.event-card-img:hover{
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: all .3s ease;
}
.grid-item-img{
  transition: all .3s ease;
}
.grid-item-img:hover{
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: all .3s ease;
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .event-card{
    margin-left: initial;
    max-width: 50%;
  }
  .event-card-right{
    max-width: 50%;
    margin-right: initial;
    float: initial;
  }
  .event-row{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .grid-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    flex: 0 0 auto;
    flex-basis: 100%;
  }
  .resources-section{
    width: 75%;
  }
}

.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexend{
  margin-left: auto;
  margin-right: 10%;
}
.button-small{
  background-color: transparent;
  font-family: "Poppins";
  border: 3px solid white;
  color: white;
  font-size: 14px;
  line-height: 6;
  padding: 8px 24px 8px;
  border-radius: 45px;
  transition: all .3s ease;
}
.button-small:hover{
  background-color: white;
  border: 3px solid white;
  color: black;
  font-size: 14px;
  line-height: 6;
  padding: 8px 24px 8px;
  border-radius: 45px;
}

.button-dark{
    border: 3px solid #356A69;
    color: white;
    background-color: #356A69;
    font-family: "Poppins";
    font-size: 15px;
    line-height: 6;
    padding: 8px 24px 8px;
    border-radius: 45px;
    transition: all .3s ease;
    box-shadow: 0 15px 20px hsl(0deg 0% 70% / 40%);
}

.button-dark:hover{
    border: 3px solid #356A69;
    background-color: white;
    color: #356A69;
    font-size: 15px;
    line-height: 6;
    padding: 8px 24px 8px;
    border-radius: 45px;
    transition: all .3s ease;
}

a:hover i.fa-cog{
  color: #356A69;
  transition: all .3s ease;
}

.calendar-months-container {
  gap: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-left: 10%;
}

.calendar-month-image {
  width: 85%;
}

.calendar-legend-image {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: rgba(251, 242, 230, 0.801);
}

@media only screen and (max-width: 1000px) {
  .calendar-months-container {
    gap: 8vw;
  }
  .events {
    min-height: 100vh;
  }
}